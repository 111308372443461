import { useTranslation } from 'react-i18next';

import svFlag from '../images/flags/se.svg';
import gbFlag from '../images/flags/gb.svg';

function useLanguages() {
  const { t, i18n } = useTranslation();
  const languages = [
    {
      code: 'en',
      label: 'EN',
      name: t('english'),
      image: gbFlag,
    },
    {
      code: 'sv',
      label: 'SV',
      name: t('swedish'),
      image: svFlag,
    },
  ];
  const currentLanguage = languages.filter(
    (lang) => lang.code === i18n.language,
  )?.[0];

  return {
    languages,
    currentLanguage,
  };
}

export default useLanguages;
