import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
// import { rem } from 'polished';
import Sticky from 'react-sticky-el';
import { useTranslation } from 'react-i18next';

import CookieConsent from '../CookieConsent';
import Header from '../Header';
import Footer from '../Footer';
import SiteLogo from '../SiteLogo';
// import Drawer from '../Drawer';
// import AccordionMenu from '../AccordionMenu';
// import LanguageSwitcher from '../LanguageSwitcher';
// import { MULTILINGUAL } from '../../constants';

const Root = styled.div`
  position: relative;
  /* background-color: ${(props) => props.theme.palette.background.content}; */
`;

const HeaderWrap = styled.div`
  position: absolute; /* Use Absolute if overlapping should occur per default */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  /* background-color: ${(props) => props.theme.palette.background.content}; */
`;

const StickyWrap = styled(Sticky)``;

const Content = styled.div`
  margin: 0 auto;
  background-color: ${(props) => props.theme.palette.background.content};
  border-bottom: 1px solid ${(props) => props.theme.palette.background.content}; /* Fix to preven bottom-margin to mess with spacing */

  /* max-width: ${(props) => props.theme.grid.maxWidth};
  padding: 0 1rem 1.5rem; */
`;

// const LanguageCol = styled.div`
//   margin-top: ${rem(20)};
//   padding: 0 ${(props) => props.theme.spacing.columnGutter}
//     ${(props) => props.theme.spacing.columnGutter};
//   flex-grow: 0;
//   flex-shrink: 1;
//   justify-content: flex-end;
//   align-items: center;
// `;

const CtaButton = styled.button`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: ${(props) => props.theme.palette.colors.cta};
`;

// const query = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allWpMenu {
//       edges {
//         node {
//           id
//           name
//           slug
//           locations
//           language
//           menuItems {
//             nodes {
//               id
//               label
//               title
//               path
//               parentId
//               url
//               target
//               order
//               cssClasses
//             }
//           }
//         }
//       }
//     }
//   }
// `;

const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

function Layout({
  children,
  headerProps,
  withHeader,
  withFooter,
  background,
  style,
}) {
  // const [drawerOpen, setIsDrawerOpen] = useState(false);
  const [isHeaderStuck, setIsHeaderStuck] = useState(false);
  const {
    //  i18n,
    t,
  } = useTranslation();

  // const toggleDrawer = () => {
  //   setIsDrawerOpen(!drawerOpen);
  // };

  // // https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-source-wordpress/docs/tutorials/using-wordpress-menus.md#working-with-hierarchical-data
  // const flatListToHierarchical = (
  //   data = [],
  //   { idKey = 'id', parentKey = 'parentId', childrenKey = 'children' } = {},
  // ) => {
  //   const tree = [];
  //   const childrenOf = {};
  //   data.forEach((item) => {
  //     const newItem = { ...item };
  //     const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
  //     childrenOf[id] = childrenOf[id] || [];
  //     newItem[childrenKey] = childrenOf[id];
  //     // eslint-disable-next-line no-unused-expressions
  //     parentId
  //       ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
  //       : tree.push(newItem);
  //   });
  //   return tree;
  // };

  const handleSticky = (isStuck) => {
    setIsHeaderStuck(isStuck);
  };

  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {({
        site,
        // allWpMenu
      }) => {
        // const getMenu = (location) => {
        //   const items = allWpMenu.edges
        //     .filter(({ node }) => node.language === i18n.language)
        //     .filter(({ node }) =>
        //       node?.locations?.find(
        //         (itemLocation) => itemLocation === location,
        //       ),
        //     )
        //     .reduce(
        //       (navs, { node: nav }) =>
        //         flatListToHierarchical(nav.menuItems.nodes).map((item) => ({
        //           ...item,
        //           id: item.id,
        //           label: item.label,
        //           path: item.url,
        //         })),
        //       {},
        //     );

        //   return items.length ? items : null;
        // };

        const stickyHeader = false;

        return (
          <Root
            style={{
              ...style,
              backgroundColor: background.color,
              backgroundImage: background.image,
            }}
          >
            {/* <Drawer open={drawerOpen} toggle={toggleDrawer}>
              <AccordionMenu items={getMenu('MOBILE')} direction="vertical" />
              {MULTILINGUAL && (
                <LanguageCol>
                  <LanguageSwitcher />
                </LanguageCol>
              )}
            </Drawer> */}

            {withHeader && (
              <HeaderWrap>
                <StickyWrap
                  as={!stickyHeader && 'div'}
                  stickyClassName="stuck"
                  onFixedToggle={handleSticky}
                  topOffset={200}
                >
                  <Header
                    siteTitle={site.siteMetadata.title}
                    Logo={SiteLogo}
                    // drawerToggle={toggleDrawer}
                    // navigationSub={getMenu('SUB')}
                    // navigationMain={getMenu('MAIN')}
                    stuck={isHeaderStuck}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...headerProps}
                  />
                </StickyWrap>
              </HeaderWrap>
            )}

            <Content>
              <main>{children}</main>
              {false && <CtaButton>{t('ctaButton')}</CtaButton>}
            </Content>

            {withFooter && (
              <Footer
                siteTitle={site.siteMetadata.title}
                logo={<SiteLogo style={{ display: 'inline-block' }} />}
                // navigation={getMenu('FOOTER')}
              />
            )}

            <CookieConsent />
          </Root>
        );
      }}
    </StaticQuery>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerProps: PropTypes.oneOfType([PropTypes.object]),
  withHeader: PropTypes.bool,
  withFooter: PropTypes.bool,
  background: PropTypes.shape({
    image: PropTypes.string,
    color: PropTypes.string,
  }),
  style: PropTypes.oneOfType([PropTypes.object]),
};

Layout.defaultProps = {
  headerProps: undefined,
  withHeader: true,
  withFooter: true,
  background: {
    image: null,
    color: null,
  },
  style: {},
};

export default Layout;
