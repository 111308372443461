import React from 'react';
import PropTypes from 'prop-types';
import { rem, lighten } from 'polished';
import styled, { css } from 'styled-components';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { breakpoint } from '../../theme';
import { getPathPrefix } from '../../utils/i18n';
import { MULTILINGUAL } from '../../constants';

import Button from '../Button';
import DropdownMenu from '../DropdownMenu';
import LanguageSwitcher from '../LanguageSwitcher';
import Link from '../Link';

import { NavbarSubItem } from '../Menu';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: ${(props) => props.theme.palette.background.body}; */
  /* max-width: ${(props) => props.theme.grid.maxWidth}; */
  margin: 0 auto;
  padding-left: ${(props) => props.theme.spacing.columnGutter};
`;

const LogoCol = styled.div`
  display: flex;
  margin: 0;
  padding: 0 ${(props) => props.theme.spacing.columnGutter};
  flex-shrink: 0;
  align-items: center;
`;

const NavigationCol = styled.div`
  margin: 0;
  /* padding: 0 ${(props) => props.theme.spacing.columnGutter}; */
  flex-grow: 1;
  flex-shrink: 1;
`;

const NavigationColSub = styled.div`
  margin-bottom: 0.4rem;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: flex-end;
  display: none;
  font-size: ${rem(15.5)};

  ${breakpoint('large')} {
    display: flex;
  }
`;

const NavigationColMain = styled.div`
  margin: 0;
  justify-content: flex-end;
  display: none;

  ${breakpoint('large')} {
    display: block;
  }
`;

const LanguageCol = styled.div`
  margin: 0;
  padding: 0 ${(props) => props.theme.spacing.columnGutter};
  flex-grow: 0;
  flex-shrink: 1;
  display: none;
  justify-content: flex-end;
  align-items: center;
  /* border-left: 1px solid ${(props) => props.theme.global.border.color}; */

  ${breakpoint('large')} {
    display: flex;
  }
`;

const ToggleCol = styled.div`
  margin: 0;
  padding-left: ${(props) => `${props.theme.spacing.columnGutter}`};
  padding-right: ${(props) => `${props.theme.spacing.columnGutter}`};
  flex-grow: 0;
  display: flex;
  justify-content: flex-end;
`;

// const MenuToggle = styled.button`
//   appearance: none;
//   background: none !important;
//   border: 0;
//   padding: 0.6rem 0.6rem 0.6rem 0;
//   outline: 0;
//   margin: 0;

//   ${breakpoint('large')} {
//     display: none;
//   }

//   color: ${(props) =>
//     props.color === 'negative'
//       ? props.theme.components.menu.item.colorNegative
//       : props.theme.components.menu.item.color} !important;
// `;

// const ToggleIcon = styled(FontAwesomeIcon)`
//   font-size: ${rem(20)};
// `;

const LogoWrap = styled(Link)`
  display: block;
  color: white;
  text-decoration: none;
  width: ${rem(134)};
  flex-shrink: 0;

  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
`;

const CtaButton = styled(Button)`
  font-size: ${rem(15)};
  padding: ${rem(10)} ${rem(15)};
  margin-right: ${rem(15)};

  ${breakpoint('large')} {
    /* margin-left: ${rem(15)}; */
    margin-right: 0;
  }
`;

const TopBar = styled.header`
  position: relative;
  /* transition: box-shadow 0.4s, background-color 0.25s ease-in-out; */
  padding: ${rem(26)} 0;

  ${breakpoint('large')} {
    padding: ${rem(30)} 0;
  }

  ${CtaButton} {
    display: none;
  }

  @keyframes toggleHeader {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0%);
    }
  }

  &.stuck {
    background-color: ${(props) => props.theme.palette.background.content};
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
    animation-name: toggleHeader;
    animation-duration: 0.35s;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    padding: ${rem(10)} 0;

    ${breakpoint('large')} {
      padding: ${rem(20)} 0;
    }

    ${LogoWrap} {
      /* width: ${rem(50)}; */

      ${breakpoint('large')} {
        /* width: ${rem(60)}; */
      }
    }

    ${NavigationColSub} {
      display: none;
    }

    ${CtaButton} {
      display: block;
    }
  }
`;

function Header({
  Logo,
  // drawerToggle,
  navigationMain,
  navigationSub,
  backgroundColor,
  stuck,
}) {
  const {
    i18n,
    // t
  } = useTranslation();

  const menuColor = 'default'; //  'default' or 'negative'

  return (
    <TopBar
      style={
        backgroundColor
          ? { backgroundColor, borderColor: lighten(0.1, backgroundColor) }
          : {}
      }
      className={stuck && 'stuck'}
      stuck={stuck}
    >
      <Container>
        <LogoCol>
          <LogoWrap to={getPathPrefix(i18n.language)}>
            <Logo />
          </LogoWrap>
        </LogoCol>
        <NavigationCol>
          {(navigationSub || MULTILINGUAL) && (
            <NavigationColSub>
              <DropdownMenu
                items={navigationSub}
                color={menuColor}
                align="end"
                // itemComponent={<NavbarSubItem color={menuColor} />}
                itemComponent={NavbarSubItem}
              />
              {MULTILINGUAL && (
                <LanguageCol>
                  <LanguageSwitcher showCurrent={false} color={menuColor} />
                </LanguageCol>
              )}
            </NavigationColSub>
          )}
          <NavigationColMain>
            {navigationMain && (
              <DropdownMenu
                items={navigationMain}
                color={menuColor}
                align="end"
              />
            )}
          </NavigationColMain>
        </NavigationCol>
        <ToggleCol>
          {/* <CtaButton link="https://online.bookvisit.com/accommodation/list?channelId=7b262c95-0147-485a-956a-6dfddc118d08">
            {t('bookCtaButton')}
            </CtaButton> */}
          {/* <MenuToggle
            type="button"
            onClick={drawerToggle}
            color={menuColor}
            aria-label="Mobile menu toggle"
          >
            <ToggleIcon icon={faBars} />
          </MenuToggle> */}
        </ToggleCol>
      </Container>
    </TopBar>
  );
}

Header.propTypes = {
  Logo: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  // drawerToggle: PropTypes.func.isRequired,
  navigationMain: PropTypes.arrayOf(PropTypes.object),
  navigationSub: PropTypes.arrayOf(PropTypes.object),
  backgroundColor: PropTypes.string,
  stuck: PropTypes.bool,
};

Header.defaultProps = {
  navigationMain: [],
  navigationSub: [],
  backgroundColor: undefined,
  stuck: false,
};

export default Header;
