import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { StaticQuery, graphql } from 'gatsby';

import Menu, { DiscreteItem } from '../Menu';
import Link from '../Link';
import SocialLinks from '../SocialLinks';
import Row from '../Row';
import Col from '../Col';
import LanguageSwitcher from '../LanguageSwitcher';
// import NewsletterWidget from '../NewsletterWidget';
import { MULTILINGUAL } from '../../constants';

import { breakpoint } from '../../theme';
import useLanguages from '../../hooks/useLanguages';

const Root = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.palette.background.content};
`;

const Container = styled.div`
  padding: ${rem(43)} ${(props) => props.theme.spacing.columnGutter} ${rem(20)};
  width: 100%;
  /* max-width: ${(props) => props.theme.grid.maxWidth}; */
  margin: auto;
`;

const Widgets = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 0;

  ${breakpoint('large')} {
    flex-direction: row;
    align-items: flex-start;
  }

  ${Col} {
    width: auto;
  }
`;

const Links = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-direction: column;
  width: 100%;
  margin-top: ${rem(60)};

  ${breakpoint('large')} {
    flex-direction: row;
  }

  ${Col} {
    width: auto;
  }
`;

const MainFooter = styled.footer`
  text-align: center;
  /* color: ${(props) => props.theme.palette.colors.white}; */

  ${breakpoint('large')} {
    text-align: left;
  }
`;

// const LogoCol = styled(Col)`
//   margin: 0;
//   flex-shrink: 1;
//   font-size: ${rem(15)};
//   width: 100% !important;
//   max-width: ${rem(220)};
//   margin-bottom: ${rem(35)};

//   ${breakpoint('large')} {
//     margin-bottom: 0;
//   }

//   img {
//     width: ${rem(90)};
//   }
// `;

const FooterTextCol = styled(Col)`
  margin: 0;
  flex-grow: 1;
  max-width: ${rem(240)};
  margin-bottom: ${rem(22)};

  ${breakpoint('large')} {
    margin-bottom: 0;
  }
`;

const AssociationCol = styled(Col)`
  margin: 0;
  flex-grow: 1;
  margin-bottom: ${rem(22)};
  /* max-width: ${rem(240)}; */

  ${breakpoint('large')} {
    margin-bottom: 0;
  }

  & .inline-gatsby-image-wrapper,
  & img {
    width: ${rem(74)} !important;
    margin-right: 1.75rem;
  }
`;

const LanguageCol = styled(Col)`
  margin: 0;
  flex-grow: 0;
  flex-shrink: 1;
  max-width: ${rem(220)};
  font-size: ${rem(15)};
  /* margin-bottom: ${rem(12)}; */

  /* ${breakpoint('large')} {
    margin-bottom: ${rem(12)};
  } */
`;

// const NewsletterCol = styled(Col)`
//   margin: 0;
//   flex-grow: 0;
//   flex-shrink: 1;
//   max-width: ${rem(320)};
//   font-size: ${rem(15)};
//   /* margin-bottom: ${rem(12)}; */

//   /* ${breakpoint('large')} {
//     margin-bottom: ${rem(12)};
//   } */
// `;

const NavigationCol = styled(Col)`
  margin: 0;
  flex-grow: 1;
  text-align: center;
  flex-wrap: wrap;
  margin-bottom: ${rem(22)};

  ${breakpoint('large')} {
    text-align: left;
    margin-bottom: 0;
  }
`;

const SocialCol = styled(Col)`
  flex-grow: 0;
`;

const BottomLine = styled.div`
  display: flex;
  padding-top: ${rem(20)};
  padding-bottom: ${rem(20)};
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin: auto;
  background-color: ${(props) => props.theme.palette.colors.greyLight};
  /* color: ${(props) => props.theme.palette.colors.white}; */
  font-size: ${rem(15)};
  text-align: center;
  text-transform: none;
  font-family: ${({ theme }) => theme.typography.fonts.alt};
  font-weight: ${({ theme }) => theme.typography.weights.alt.normal};

  ${breakpoint('large')} {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }
`;

const BottomLineContainer = styled(Row)`
  width: 100%;
  /* max-width: ${(props) => props.theme.grid.maxWidth}; */
  margin: auto;
  /* opacity: 0.5; */
`;

const Copyright = styled(Col)`
  width: 100%;

  ${breakpoint('large')} {
    width: 50%;
    text-align: left;
  }
`;

const Terms = styled(Col)`
  width: 100%;
  margin-top: ${rem(6)};

  ${breakpoint('large')} {
    width: 50%;
    text-align: right;
    margin-top: 0;
  }

  a {
    color: ${(props) => props.theme.components.menu.item.color};

    &:hover {
      color: ${(props) => props.theme.components.menu.item.hover};
    }
  }
`;

// const FooterNote = styled.p`
//   font-size: ${rem(12)};
//   color: ${(props) => props.theme.components.menu.item.colorNegative};
// `;

const FooterMenu = styled.div`
  /* margin-left: -1.15rem;
  margin-right: -1.15rem; */
`;

// const PreFooter = styled(Row)`
//   border-top: 1px solid ${(props) => props.theme.global.border.color};
//   padding-top: ${rem(30)};
//   padding-bottom: ${rem(30)};
// `;

// const PreFooterCol = styled(Col)`
//   text-align: center;
//   margin-bottom: 3.5rem;

//   &:last-child {
//     margin-bottom: 0;
//   }

//   ${breakpoint('large')} {
//     margin-bottom: 0;
//   }

//   /* width: 100%;

//   ${breakpoint('large')} {
//     width: 50%;
//   } */
// `;

const FooterContacts = styled.div`
  font-size: ${rem(15)};

  p {
    margin-bottom: 0.85em;
  }
`;

const FooterContent = styled.div`
  font-size: ${rem(15)};

  p {
    margin-bottom: 0.85em;
  }

  h4,
  h5,
  h6 {
    font-size: ${rem(18)};
    margin-bottom: ${rem(14)};
    color: ${(props) => props.theme.palette.colors.primary};
    text-transform: uppercase;
  }
`;

const FooterTitle = styled.h5`
  font-size: ${rem(22)};
  margin-bottom: ${rem(10)};
  color: ${(props) => props.theme.palette.colors.primary};
`;

const Email = styled.p`
  font-size: ${rem(28)};
`;

// const query = graphql`
//   query {
//     wp {
//       themeFooterSettings {
//         footerOptions {
//           footerContacts
//           footerContactsEn
//           footerContent
//           footerContentEn
//         }
//         generalOptions {
//           city
//           location {
//             city
//             country
//             countryShort
//             latitude
//             longitude
//             placeId
//             state
//             postCode
//             stateShort
//             streetName
//             streetAddress
//             streetNumber
//             zoom
//           }
//           postalCode
//           street
//         }
//       }
//       themeGeneralSettings {
//         socialOptions {
//           facebook
//           fieldGroupName
//           instagram
//           linkedin
//           pinterest
//           twitter
//           vimeo
//           youtube
//         }
//       }
//     }
//   }
// `;

const query = graphql`
  query {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        siteLanguage
        ogLanguage
        author
        twitter
        facebook
        socialLinks {
          facebook
          instagram
          linkedin
          pinterest
          twitter
          vimeo
          youtube
        }
      }
    }
    logo: file(relativePath: { eq: "logo/logo.svg" }) {
      publicURL
    }
  }
`;

function Footer({
  // logo,
  navigation,
}) {
  const { t } = useTranslation();
  const { currentLanguage } = useLanguages();
  const policyLink = '';
  const withSocial = false;
  const withCred = false;
  const credLink = 'https://www.simongoot.com/';

  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {() =>
        // {
        //   wp: {
        //     // themeGeneralSettings,
        //     themeFooterSettings: { footerOptions },
        //   },
        // },
        {
          const footerMeta = {
            en: {
              // content: footerOptions?.footerContentEn,
              content: null,
              // contactInfo: footerOptions?.footerContactsEn,
              contactInfo: null,
            },
            sv: {
              // content: footerOptions?.footerContent,
              content: null,
              // contactInfo: footerOptions?.footerContacts,
              contactInfo: null,
            },
          };

          return (
            <Root>
              <MainFooter>
                <Container>
                  <Widgets>
                    {/* <LogoCol>{logo}</LogoCol> */}
                    <FooterTextCol>
                      <FooterTitle>{t('contactUs')}</FooterTitle>
                      <FooterContacts>
                        <Email>hello@prep.group</Email>
                      </FooterContacts>
                      {footerMeta[currentLanguage.code]?.contactInfo && (
                        <FooterContacts
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html:
                              footerMeta[currentLanguage.code]?.contactInfo,
                          }}
                        />
                      )}
                    </FooterTextCol>
                    {footerMeta[currentLanguage.code]?.content && (
                      <AssociationCol>
                        <FooterContent
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: footerMeta[currentLanguage.code]?.content,
                          }}
                        />
                      </AssociationCol>
                    )}
                    {/* <NewsletterCol>
                      <FooterTitle>{t('newsletter')}</FooterTitle>
                      <NewsletterWidget />
                    </NewsletterCol> */}
                    {MULTILINGUAL && (
                      <LanguageCol>
                        <LanguageSwitcher color="default" />
                      </LanguageCol>
                    )}
                  </Widgets>
                  <Links>
                    <NavigationCol>
                      <FooterMenu>
                        <Menu
                          items={navigation}
                          align="start"
                          direction="horizontal"
                          itemComponent={DiscreteItem}
                        />
                      </FooterMenu>
                    </NavigationCol>
                    {withSocial && (
                      <SocialCol>
                        <SocialLinks />
                      </SocialCol>
                    )}
                  </Links>
                </Container>
              </MainFooter>
              <BottomLine>
                <BottomLineContainer>
                  <Copyright>&copy; {t('copyrightLine')}</Copyright>{' '}
                  <Terms>
                    {withCred && credLink && (
                      <Link to={credLink}>{t('developerCred')}</Link>
                    )}
                    {policyLink && (
                      <Link to={policyLink}>{t('privacyPolicy')}</Link>
                    )}
                  </Terms>
                </BottomLineContainer>
              </BottomLine>
            </Root>
          );
        }
      }
    </StaticQuery>
  );
}

Footer.propTypes = {
  // logo: PropTypes.node.isRequired,
  navigation: PropTypes.arrayOf(PropTypes.object),
};

Footer.defaultProps = {
  navigation: [],
};

export default Footer;
