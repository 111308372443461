import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { navigate } from '@reach/router';
import { Trans, useTranslation } from 'react-i18next';
import { rgba } from 'polished';

import Link from '../Link';
import Button from '../Button';

import { breakpoint } from '../../theme';

const Root = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
  max-width: 100%;
  width: 37rem;
  padding: ${(props) => props.theme.spacing.gutter};
`;

const Card = styled.div`
  padding: ${(props) => props.theme.spacing.gutter};
  background-color: ${(props) =>
    rgba(props.theme.palette.colors.lightBlack, 0.95)};
  width: 100%;
  overflow: hidden;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 0.85rem;
`;

const Text = styled.div`
  p {
    margin: 0;
  }

  a {
    color: ${(props) => props.theme.palette.colors.cta};
    font-weight: ${(props) => props.theme.typography.weights.primary.bold};
  }
`;

const ButtonWrap = styled.div`
  margin: 0 -0.5rem 0;
  display: flex;
  flex-direction: column;
  font-size: 0.78rem;

  ${breakpoint('medium')} {
    flex-direction: row;
    align-items: flex-start;
    font-size: 0.85rem;
  }

  a {
    color: ${(props) => props.theme.palette.colors.black} !important;
    background-color: ${(props) => props.theme.palette.colors.white} !important;
    padding: 0.5rem 0.75rem !important;
  }
`;

function CookieConsent() {
  const defaultVisibility =
    typeof window !== 'undefined' &&
    window.localStorage.getItem('dnt') === null;

  const [isVisible, setVisible] = useState(defaultVisibility);
  const { t } = useTranslation();
  // const { wpPage: privacyPage } = useStaticQuery(graphql`
  //   query {
  //     wpPage(isPrivacyPage: { eq: true }) {
  //       uri
  //     }
  //   }
  // `);

  const {
    site: { privacyUrl: privacyPage },
  } = useStaticQuery(graphql`
    query {
      site {
        buildTime(formatString: "YYYY-MM-DD")
        siteMetadata {
          defaultTitle: title
          defaultDescription: description
          siteUrl
          privacyUrl
          siteLanguage
          ogLanguage
          author
          twitter
          facebook
          socialLinks {
            facebook
            instagram
            linkedin
            pinterest
            twitter
            vimeo
            youtube
          }
        }
      }
    }
  `);

  const handleConsent = useCallback(() => {
    setVisible(false);

    window.localStorage.setItem('dnt', 0);

    if (window.dataLayer) {
      window.dataLayer.push({
        cookiesAccepted: true,
        event: 'cookiesConsentChange',
      });
    } else {
      navigate(0);
    }
  }, [setVisible]);

  const handleReject = useCallback(() => {
    setVisible(false);

    window.localStorage.setItem('dnt', 1);
  }, [setVisible]);

  if (!isVisible) {
    return null;
  }

  const buttonStyle = {
    fontSize: '1em',
    padding: '0.65em 1.75em',
    margin: '0.5rem 0.5rem 0',
  };

  return (
    <Root>
      <Card>
        <Text>
          <h5>{t('consentTitle')}</h5>
          <p>
            {t('consentMessage')}{' '}
            {privacyPage?.uri && (
              <Trans i18nKey="consentPrivacy">
                Read about how we use your data in our{' '}
                <Link to={privacyPage?.uri}>privacy policy</Link>.
              </Trans>
            )}
          </p>
        </Text>
        <ButtonWrap>
          <Button
            type="button"
            onClick={handleConsent}
            style={buttonStyle}
            className="cta"
          >
            {t('consentAccept')}
          </Button>
          <Button
            type="button"
            onClick={handleReject}
            style={buttonStyle}
            className="grey"
          >
            {t('consentReject')}
          </Button>
        </ButtonWrap>
      </Card>
    </Root>
  );
}

export default CookieConsent;
