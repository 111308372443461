import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { em } from 'polished';
import { useTranslation } from 'react-i18next';

import { breakpoint } from '../../theme';
import useLanguages from '../../hooks/useLanguages';

const Switcher = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme, color }) =>
    color === 'negative'
      ? theme.components.menu.item.colorNegative
      : theme.components.menu.item.color};

  ${breakpoint('large')} {
    /* padding: ${(props) => props.theme.spacing.gutter} 0; */
  }
`;

const LanguageItem = styled.button`
  display: flex;
  align-items: center;
  color: inherit;
  padding: 0.5rem;
  appearance: none;
  outline: none;
  border: 0;
  line-height: 1;
  cursor: pointer;
  opacity: 0.4;
  background: none;
  margin-bottom: 0;
  font-size: inherit;
  font-weight: ${(props) => props.theme.components.menu.item.fontWeight};
  font-family: ${(props) => props.theme.typography.fonts.primary};
  letter-spacing: ${(props) => props.theme.components.menu.item.letterSpacing};
  text-transform: ${(props) => props.theme.components.menu.item.textTransform};

  &:hover {
    opacity: 0.8 !important;
    background: none;
    color: inherit;
  }

  ${({ active }) => active && `opacity: 1;`}
`;

const LanguageIcon = styled.div`
  display: block;
  border-radius: 999px;
  overflow: hidden;
  width: ${em(22)};
  height: ${em(22)};
  margin-right: ${em(9)};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
`;

function LanguageSwitcher({ showCurrent, color }) {
  const { i18n } = useTranslation();
  const languages = useLanguages();

  function handleChangeLanguage(lang) {
    i18n.changeLanguage(lang);
  }

  return (
    <Switcher color={color}>
      {languages.map((lang) => {
        const isActive = lang.code === i18n.language;

        return !isActive || showCurrent ? (
          <LanguageItem
            type="button"
            key={lang.code}
            onClick={() => handleChangeLanguage(lang.code)}
            active={isActive || !showCurrent}
          >
            <LanguageIcon
              style={{ backgroundImage: `url(${lang.image})` }}
              title={lang.name}
            />
            {lang.label}
          </LanguageItem>
        ) : null;
      })}
    </Switcher>
  );
}

LanguageSwitcher.propTypes = {
  showCurrent: PropTypes.bool,
  color: PropTypes.oneOf(['default', 'negative']),
};

LanguageSwitcher.defaultProps = {
  showCurrent: true,
  color: 'default',
};

export default LanguageSwitcher;
